<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <extensoes-rede-data-table
            :data="data"
            :loading="loading"
            @status:changed="getExtensoesRedePorIdEmpresa"
            @rechargeTable="getExtensoesRedePorIdEmpresa"
            @searchRequested="trocarPagina"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompanyService from '@/services/CompanyService';
import refreshDataMixins from '@/mixins/refreshDataMixins';

export default {
  mixins: [refreshDataMixins],

  components: {
    ExtensoesRedeDataTable: () =>
        import('@/components/empresas/ExtensoesRedeDataTable.vue')
  },

  data: () => ({
    data: [],
    loading: false,
  }),

  methods: {
    getExtensoesRedePorIdEmpresa(options = {}) {
      this.loading = true;
      this.items = [];

      const data = {
        offset: (options.page - 1) * options.itemsPerPage,
        limit: options.itemsPerPage,
      }

      CompanyService.getNetworkExtentsByCompanyId(this.empresaId, data)
          .then((response) => {
            this.data = response.data;
          })
          .catch(() => {
            this.$toast.error('Erro ao pesquisar as extensões.', '', {
              position: 'topRight'
            });
          })
          .finally(() => (this.loading = false));
    },

    trocarPagina(options) {
      this.getExtensoesRedePorIdEmpresa(options);
    },
  },

  computed: {
    empresaId() {
      return this.$route.params.id;
    },
  }
};
</script>
